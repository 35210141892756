<template>
  <div class="about">
    <header>
      <top-title
      title="WHAT WE DO"
      subTitle="FB廣告投放、行銷、粉絲團經營、創意影片平台">
      </top-title>
    </header>
    <div class="container">
      <page-main-body
        title="關於采億科技、采盟數位"
        subTitle="About Tsaiyi Technology &amp; Tsaimeng Media"
        paragraph1="采億科技股份有限公司創立於 2013 年 12 月，創辦人為林苡暄 Vivi ，其擁有電子商務與社群行銷專業超過 14 年經驗，采億前身飛訊行銷公司，公司概念發想以 fashioner 為主軸並起源於新莊一處近 30 年的舊公寓，當時以顧問服務為主，為知名的網路賣家做社群行銷服務。2014 年 2 月加入重要合夥經營者許峰誌 Sam ，其擁有資料庫系統與遊戲程式背景 5 - 6 年。同年公司更名為采億科技有限公司，這幾年來我們致力於社群商務、遊戲行銷專業根基打底推出一系列服務。2019 年因團隊規模擴大，采億科技有限公司變更為采億科技股份有限公司。"
        paragraph2="在 70、80 年代到現在 00 世代裡，銷售行為不再是單向，而是有溫度的雙向溝通。網路零售買賣所面臨是社群評價與口碑考驗，從粉絲到聚粉、聚粉到鐵粉的過程中需要一層層堆疊，無論是產品設計到行銷包裝，都需要專業團隊量身打造。而品牌與粉絲之間不再只是單純的買、賣關係，而是可以透過一系列趣味功能互動與內容溫度產生集客效益。"
        paragraph3="鑑於此 2015 年創立分支采盟數位媒體股份有限公司，主力服務為網路廣告投放、口碑內容、媒體採購等...更加專注並強大專業能力於此項服務。我們生活在一個網路時代裡，唯有持續進步才能生存與提共優質的服務。采億科技、采盟數位不只賣服務、賣口碑、更要賣創意、賣行銷，協助客戶在浩瀚的宇宙中，脫穎而出，成為產業的 NO.1。"
        paragraph4="2018年在馬來西亞創立de Balets小巴黎香水洗髮精品牌，2019年在台灣創立小巴黎股份有限公司正式進軍台灣市場，提倡自然輕奢香氛生活品牌；並於2020年創立D.D.C.C.寵物品牌。"
        paragraph5="在這數位時代裡，我們常常遇到計畫趕不上變化。新型冠狀病毒COVID-19疫情全球蔓延下，市場變化更加快速。只有不斷成長與順應時代的改變，企業才能繼續運作。我們最大目標為集團化經營，並提倡利潤中心制度，鼓勵員工內部創業，建立百年企業。"
      >
      </page-main-body>
      
      <div class="path">
        <h1>采億 / 采盟心路歷程</h1>
        <event-block class="start"
          time="2013 / 12"
          event="創立飛訊行銷有限公司"
          extra=""
          hyper="">
        </event-block>
        <div class="pathGrowArea">
          <div class="tree">
            <div class="upperDot dot"></div>
            <div class="bar"></div>
            <div class="bottomDot dot"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <div class="bra"></div>
            <event-block class="evt"
              time="2014 / 03"
              event='更名"采億科技有限公司"'
              extra=""
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2014 / 03"
              event='玩粉絲 活動模組 1.0 上市'
              extra=""
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2015 / 06"
              event='子公司'
              extra="采盟數位股份有限公司成立"
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2017 / 02"
              event='成立馬來西亞分公司'
              extra=""
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2017 / 08"
              event='玩粉絲 活動模組 2.0 上市'
              extra=""
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2018 / 01"
              event='成立de Balets'
              extra="de Balets小巴黎"
              hyper="https://www.debalets.com.tw/">
            </event-block>
            <event-block class="evt"
              time="2018 / 09"
              event='玩粉絲 3.0 上市 更名:'
              extra="Fans Play 全通路互動模組"
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2019 / 03"
              event='更名"采億科技股份有限公司"'
              extra=""
              hyper="">
            </event-block>
            <event-block class="evt"
              time="2020 / 05"
              event='成立D.D.C.C品牌"'
              extra="D.D.C.C"
              hyper="https://www.ddcc.com.tw/">
            </event-block>
          </div>
        </div>
        <h2>采億 / 采盟將持續邁進</h2>
      </div>
      <!--
      <div class="staffs">
        <h1>認識我們的團隊</h1>
        <staff></staff>
      </div>
      -->
    </div>
    <footer-view />
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import eventBlock from "@/components/eventBlock.vue";
import staff from "@/components/staff.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "about",
  components: {
    topTitle,
    eventBlock,
    staff,
    pageMainBody,
    footerView
  },
  data() {
    return {
      st: 0
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.currentPage = "about";
    setTimeout(() => {
      this.$parent.isLoading = false;
    }, 1000);
  },
  methods: {
    handleScroll() {
      this.st = window.pageYOffset || document.documentElement.scrollTop;
      if (this.st > $(".path").offset().top - 500) {
        $(".bar").addClass("pathGrow");
        $(".bottomDot").addClass("colorTrans");
        $(".bra").addClass("branches");
        $(".tree")
          .find(".bra")
          .each(function(idx) {
            $(this).addClass("branch" + (idx + 1));
          });
        $(".tree")
          .find(".evt")
          .each(function(idx) {
            $(this).addClass("event" + (idx + 1));
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
$gradient: linear-gradient(to bottom, #f12379, #9d5dce, #79cccf);

header {
  background-image: url("../assets/image/about/topBanner.jpg");
}

h1,
h2 {
  margin: 2px auto;
}
h2 {
  font-weight: 300;
}

.about {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  color: #707070;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .path {
    margin-top: 60px;
    width: 100%;
    .start {
      display: block;
    }
    h1 {
      @include font(medium);
      margin: 20px 0;
    }
    h2 {
      @include font(small);
      margin-top: 20px;
      margin-bottom: 50px;
    }
    .pathGrowArea {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.tree {
  @keyframes pathGrow {
    0% {
      height: 0;
      background-size: 100% 300%;
    }
    100% {
      height: calc(100% - 25px);
      background-size: 100% 100%;
    }
  }

  @keyframes colorTrans {
    0% {
      background-color: #f12379;
    }
    38% {
      background-color: #9d5dce;
    }
    72% {
      background-color: #79cccf;
    }
  }
  position: relative;
  max-width: 400px;
  width: 80%;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bar {
    display: none;
    background-size: 100% 100%;
    background-image: $gradient;
    width: 7px;
    height: calc(100% - 25px);
    &.pathGrow {
      display: unset;
      animation: pathGrow 4s ease;
    }
  }
  .dot {
    width: 25px;
    height: 25px;
    border-radius: 100%;
  }
  .upperDot {
    background-color: #f12379;
  }
  .bottomDot {
    display: none;
    background-color: #79cccf;
    &.colorTrans {
      display: unset;
      animation: colorTrans 4s ease;
    }
  }

  $one: 10%;
  $two: 20%;
  $three: 30%;
  $four: 40%;
  $five: 50%;
  $six: 60%;
  $seven: 70%;
  $eight: 80%;
  $night: 90%;
  $branches: $one, $two, $three, $four, $five, $six, $seven, $eight, $night;

  @for $i from 1 through length($branches) {
    $c: nth($branches, $i);
    .branch#{$i} {
      top: $c;
      background-position-y: $c;
      animation: branch#{$i} 4s ease;
      @keyframes branch#{$i} {
        0% {
          width: 0;
        }
        #{$c} {
          width: 0;
        }
        100% {
          width: 50%;
        }
      }
    }
    .event#{$i} {
      display: flex;
      bottom: 100 - $c;
      animation: event#{$i} 4s ease;
      @keyframes event#{$i} {
        0% {
          opacity: 0;
        }
        #{$c} {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .branches {
    justify-self: center;
    position: absolute;
    width: 50%;
    height: 1px;
    background-image: $gradient;
    background-size: 100% 100000%;
    &:nth-child(even) {
      transform: translateX(51%);
    }
    &:nth-child(odd) {
      transform: translateX(-51%);
    }
  }
}
.evt {
  display: none;
  position: absolute;
  width: 360px;
  @media #{$tablet} {
    width: 320px;
  }
  @media #{$phone} {
    width: 200px;
  }
  @media #{$slim} {
    font-size: 11px;
    width: 158px;
  }
  &:nth-child(odd) {
    transform: translateX(100%);
    @media #{$tablet} {
      transform: translateX(60%);
    }
    @media #{$phone} {
      transform: translateX(52%);
    }
  }
  &:nth-child(even) {
    transform: translateX(-100%);
    @media #{$tablet} {
      transform: translateX(-60%);
    }
    @media #{$phone} {
      transform: translateX(-52%);
    }
  }
}

.staffs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin: 30px 0;
  }
}
</style>
