<template>
  <div class="persons">
    <div class="person" @mouseover="mouseover" v-for="(person, idx) in persons" :key="idx" >
      <div class="pic"></div>
      <span>
        <div class="info">
          {{person.title}}
          <hr/>
          <div class="describe">{{person.describe}}</div>
        </div>
        <font>{{person.name}}</font>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "staff",
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      persons: [
        {name: "Vivi", title: "創辦人", describe: "公司經營及網路媒體顧問"},
        {name: "Sam", title: "執行長", describe: "公司經營及程式軟體開發"},
        {name: "Denise", title: "管理部行政經理", describe: "人力資源規劃與行政管理"},
        // {name: "Alice", title: "市場部資深經理", describe: "客戶關係管理及數位內容專案管理"},
        {name: "Winson", title: "品牌部行銷總監", describe: "數位整合行銷及品牌社群操作"},
        //{name: "Ariel", title: "品牌部行銷總監", describe: "品牌行銷及數位媒體操作"},
        //{name: "Chian", title: "設計部副理", describe: "品牌設計執行與視覺策略方向規劃"}
      ],
      src: [],
      image: "",
      key: 0
    };
  },
  mounted() {
    var colors = ["#F12379", "#9D5DCE", "#79CCCF"];
    var vm = this;
    $(".pic").each(function(idx) {
      $(this).css({
        "background-image": `url(${vm.baseUrl}static/staffs/${vm.persons[idx].name}.jpg)`
      });
    });
    $(".person span").each(function() {
      var color = colors[Math.floor(Math.random() * 3)];
      $(this).css({
        top: Math.floor(Math.random() * 20 + 50) + "%",
        left: Math.floor(Math.random() * 30) + "%",
        "border-top-color": color
      });
      $(this).find("font").css({
        "color": color
      })
    });
  },
  methods: {
    mouseover() {
      //不給mouseover事件 CSS的hover無法在ios上觸發
    }
  }
};
</script>

<style lang="scss" scoped>
.persons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 79%;
  .person {
    position: relative;
    width: 20vw;
    height: 25vw;
    min-width: 270px;
    min-height: 337px;
    .pic {
      background-size: 100% 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: 0.5s ease-out;
      padding: 0;
      filter: grayscale(100);
    }
    .info {
      position: absolute;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      align-items: center;
      top: -120px;
      opacity: 0;
      white-space: nowrap;
      hr {
        width: 100px;
        margin: 0;
      }
      .describe {
        white-space: normal;
      }
    }
    span {
      position: absolute;
      width: 0;
      height: 0;
      color: #fff;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      border-left: 126px solid transparent;
      border-right: 126px solid transparent;
      border-top: 126px solid;
      transform: scale(0.25);
      transition: 1s;
      font {
        text-align: center;
        opacity: 1;
        transition: 1s;
        font-size: 76px;
        white-space: nowrap;
      }
    }
    &:hover,
    &.hover {
      & .pic {
        background-size: 110% 110%;
        filter: grayscale(0);
      }
      & .info {
        opacity: 1;
      }
      & span {
        transform: scale(1);
        z-index: 8;
        & font {
          opacity: 0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
