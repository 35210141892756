<template>
  <div class="eventBlock">
    <span class="time">{{time}}</span>
    <span class="event">{{event}}</span>
    <span v-if="hyper==''" class="extra">{{extra}}</span>
    <a v-if="hyper!=''" target="_blank" :href="hyper" >
      <span class="extra">{{extra}}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "eventBlock",
  props: {
    time: String,
    event: String,
    extra: String,
    hyper: String
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/font-size.scss";

.eventBlock {
  @include font(small);
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  text-align: center;
}
.time {
  font-weight: 900;
}
</style>
